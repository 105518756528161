<template></template>

<script>

export default {
  name: 'Logout',
  data() { return {} },
  created: function() {
      this.$store.commit('DESTROY')
      this.$router.push('/secure/login');
  }
}
</script>
